<template>
  <div v-loading="loading" class="content">
    <!-- 上边 -->
    <el-row style="margin-bottom:10px">
      <el-col :span="12" style="display: flex;align-items: center;">
        <search @success="init()" @cancel="cancel">
          <el-form>
            <el-form-item label="库存金额为0:" :label-width="formLabelWidth">          
              <el-radio-group v-model="listQuery.hideEndAmount0">
                <el-radio :label="true">隐藏</el-radio>
                <el-radio :label="false">显示</el-radio>
              </el-radio-group>
            </el-form-item>

            <!-- <el-form-item label="显示未匹配的:" :label-width="formLabelWidth">          
              <el-radio-group v-model="listQuery.showWpp">
                <el-radio :label="false">隐藏</el-radio>
                <el-radio :label="true">显示</el-radio>
              </el-radio-group>
            </el-form-item> -->
            <el-form-item label="相似度匹配无库存存货:" :label-width="formLabelWidth">          
              <el-radio-group v-model="listQuery.match">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="存货类型:" :label-width="formLabelWidth">   
              <el-select filterable v-model="listQuery.type" placeholder="请选择存货类型" size="small" style="width:150px;margin-left:0" clearable multiple collapse-tags>
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </search>

        <el-button style="margin-left:10px" size="small" type="primary" plain @click="init(1)">刷新</el-button>
        <el-button :disabled="!$buttonStatus('ch_myhsff_qbck')" size="small" type="success" plain @click="oneKeyLl()">全部出库</el-button>
        <el-tooltip placement="top">
          <template #content>
            <div style="max-width: 500px;">取整单位：个、台、辆、张、支、瓶、包、箱、卷、桶、袋、打、双、对、 
                套、块、枚、罐、盆、束、羽、条、头、尾、本、份、板、片、粒、管、
                根、把、只、件、架、箱、付、副</div>
          </template>
          <el-button :disabled="!$buttonStatus('ch_myhsff_ablck')" size="small" type="success" plain @click="ablck()">按成本比例出库</el-button>
        </el-tooltip>
        <el-button :disabled="!$buttonStatus('ch_myhsff_ablck') || !info.rightInventorys || info.rightInventorys.length == 0" size="small" type="success" plain @click="ablszdj()">按比例设置单价</el-button>
      </el-col>
      <el-col :span="12">
        <div style="float:right">
          <el-button :disabled="!$buttonStatus('ch_myhsff_myhs')" size="small" v-if="info.statusDetail && info.statusDetail.tradeCount == 0" type="success" @click="doProduceClick()">贸易核算</el-button>
          <el-button :disabled="!$buttonStatus('ch_myhsff_zc')" size="small" type="success" @click="doProduce(1)">暂存</el-button>
          <el-button :disabled="!$buttonStatus('ch_myhsff_czmyhs')" size="small"  type="warning" v-if="info.statusDetail && info.statusDetail.tradeCount == 1" @click="reset()">重置贸易核算</el-button>
          <costSetting :disabled="!$buttonStatus('ch_myhsff_jzkmsz')" type="trade"></costSetting>
          <el-button size="small" type="primary" @click="daoruRight1()" icon="FolderAdd" :loading="daoruLoading" plain> 导入</el-button>
          <el-button size="small" type="primary" @click="daochuRight()" icon="FolderOpened" :loading="daochuLoading" plain> 导出</el-button>
        </div>
      </el-col>
      <el-col style="color: red;">
        <span style="font-size: 15px;">
          注意：
        </span>
        <span>
          出库为0的出库金额为0，存货数量等于出库数量时,全部出库的出库金额为存货金额，这两种情况不取excel中填写的出库金额的数值
        </span>
      </el-col>
    </el-row>

    <!-- 左边 -->
    <div class="left-content">
      <!-- 存货原材料 -->
      <div class="left-content-item">
        <el-row>
          <!-- title -->
          <div class="left-total">
            <div style="display: flex;align-items: center;">
              <el-input style="width:120px" size="small" v-model="this.leftListQuery.inventoryName" placeholder="筛选名称"></el-input>
              <el-button @click="changeLeftInventory('find')" size="small" type="primary" plain>筛选</el-button>
              <el-checkbox v-model="listQuery.showWppTemp" label="隐藏未匹配存货" @change="changeShow(listQuery.showWpp)" style="margin-left: 15px;display: flex;align-items: center;"/>
            </div>
            <div >
              <span>出库总金额：{{total.ckzje}}</span>
              <span>成本比例{{total.cbbl}}%</span>
            </div>
          </div>
          <div class="left-title">
            <input type="checkbox" :checked="isAllSelected" style="position: absolute;top: 5px;left: 7px;" @click="allSelect"/>
              存货
          </div>
          
          <el-col :span="3">
            <div class="left-box left_sort_box">
              存货数量 <span class="sort_style">
                <i class="sort-caret ascending" @click="sortChange('1','1')"></i>
                <i class="sort-caret descending" @click="sortChange('1','2')"></i>
              </span>
            </div>
          </el-col>
          <el-col :span="3">
            <div class="left-box">
              存货单价
            </div>
          </el-col>
          <el-col :span="4">
            <div class="left-box left_sort_box">
              存货金额<span class="sort_style">
                <i class="sort-caret ascending" @click="sortChange('2','1')"/>
                <i class="sort-caret descending" @click="sortChange('2','2')"/>
              </span>
            </div>
          </el-col>
          
          <el-col :span="3">
            <div class="left-box">
              出库数量
            </div>
          </el-col>
          <el-col :span="3">
            <div class="left-box">
              出库单价
            </div>
          </el-col>
          <el-col :span="4">
            <div class="left-box">
              出库金额
            </div>
          </el-col>
          <el-col :span="4">
            <div class="left-box">
              结算后数量
            </div>
          </el-col>
        </el-row>
        <el-scrollbar :height="contentStyleObj"> 
          <div>
          <!-- 明细 -->
          <el-row v-for="(item,index) in info.leftInventory" :key="index">
            <!-- title -->
            <el-col :span="24">
              <div class="leftOne subject">
                <ul style="display:inline-flex">
                  <li style="padding-right: 8px;" @click="singleSelect(item,index)">
                    <input type="checkbox" :checked="item.checked" :style="{marginLeft:0,marginTop:'9px'}"/>
                  </li>
                  <el-tooltip effect="dark" :content="item.name" placement="top" :disabled="!item.name">
                    <li style="width:200px" class="ccyc">
                      <span >{{item.name}}</span>
                    </li>
                  </el-tooltip>
                  <el-tooltip effect="dark" :content="item.subjectName" placement="top" :disabled="!item.subjectName">
                    <li style="width:180px" class="ccyc">
                      <span >科目:{{item.subjectName}}</span>
                    </li>
                  </el-tooltip>
                  <li>类型: {{$inventoryTypeFilter(item.inventoryType)}}</li>
                  <li>单位: {{item.unit}}</li>
                </ul>

              </div>
            </el-col>
            <el-col :span="24" :class="item.useCount || item.usePrice || item.useAmount?'tianshu':'tianshu2'">
              <el-row >
                <el-col :span="3">
                  <div class="left-box">
                    {{item.count}}
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="left-box">
                    <span v-if="item.count">{{(item.amount/item.count).toFixed2()}}</span>
                    <span v-else>0</span>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="left-box">
                    {{item.amount}}
                  </div>
                </el-col>
                
                <el-col :span="3">
                  <div class="left-box">
                    <qzf-input style="width: 90%;" @changeLx="changeLeft(item, 'count', item)" :disabled="info.statusDetail && info.statusDetail.tradeCount == 1 ? true : false" v-model:num="item.useCount" placeholder="请输入出库数量" />
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="left-box">
                    <qzf-input style="width: 90%;" @changeLx="changeLeft(item, 'price', item)" :disabled="info.statusDetail && info.statusDetail.tradeCount == 1 ? true : false" v-model:num="item.usePrice" placeholder="请输入出库单价" />
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="left-box">
                    <qzf-input style="width: 90%;" @changeLx="changeLeft(item, 'amount', item)" :disabled="info.statusDetail && info.statusDetail.tradeCount == 1 ? true : false" v-model:num="item.useAmount" placeholder="请输入出库金额" />
                  </div>
                </el-col>
                <el-col :span="4">
                  <!-- <div v-if="info.statusDetail && info.statusDetail.tradeCount == 1" class="left-box">
                    {{item.count}}
                  </div> -->
                  <div class="left-box">
                    {{((item.count*1000000-item.useCount*1000000)/1000000).toFixed(6) * 1}}
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          </div>
        </el-scrollbar>
        <el-row  style="background-color: #f5f7fa;">
          <el-col :span="3">
            <div class="left-box">
              {{ sumAmount }}
            </div>
          </el-col>
          <el-col :span="3">
            <div class="left-box">
              -
            </div>
          </el-col>
          <el-col :span="4">
            <div class="left-box">
              {{ sumAmount2 }}
            </div>
          </el-col>
          <el-col :span="3">
            <div class="left-box">
              {{ sumAmount3 }}
            </div>
          </el-col>
          <el-col :span="3">
            <div class="left-box">
              -
            </div>
          </el-col>
          <el-col :span="4">
            <div class="left-box">
              {{ sumAmount4 }}
            </div>
          </el-col>
          <el-col :span="4">
           <!--  <div v-if="info.statusDetail && info.statusDetail.tradeCount == 1" class="left-box">
              {{sumAmount}}
            </div> -->
            <div class="left-box">
              {{ (sumAmount*1000000 - sumAmount3*1000000)/1000000 }}
            </div>
          </el-col>
          </el-row>
      </div>
      <div class="pagination">
        <qzf-pagination v-show="leftPageTotal>0" :total="leftPageTotal" :pageSizes="[10, 20, 50, 100, 200 ,300,500,1000,3000]" v-model:page="this.leftListQuery.page" v-model:limit="this.leftListQuery.limit" @pagination="changeLeftInventory" />
      </div>
    </div>
    <!-- 右边 -->
    <div class="right-content" style="float: right;">
      <!-- 存货原材料 -->
      <div class="left-content-item">
        <el-row>
          <!-- title -->
          <div class="left-total">
            <div>
              <el-input style="width:120px;" size="small" v-model="this.rightListQuery.inventoryName" placeholder="筛选名称"></el-input>
              <el-button @click="changeRightInventory('find')" size="small" type="primary" plain>筛选</el-button>
            </div>
            <div>
              <span>销项总金额：{{total.sczje}}</span>
            </div>
          </div>
          <div class="left-title">
              销项
          </div>
          <el-col :span="4">
            <div class="left-box">
              销售数量
            </div>
          </el-col>
          <el-col :span="6">
            <div class="left-box">
              销售金额
            </div>
          </el-col>
          <el-col :span="4">
            <div class="left-box">
              核算数量
            </div>
          </el-col>
          
          <el-col :span="4">
            <div class="left-box">
              核算单价
            </div>
          </el-col>
          <el-col :span="6">
            <div class="left-box">
              核算金额
            </div>
          </el-col>
        </el-row>
        <el-scrollbar :style="{height:contentStyleObj}"> 
        <div>
          <!-- 明细 -->
          <el-row v-for="(item,index) in info.rightInventorys" :key="index">
            <!-- title -->
            <el-col :span="24">
              <div class="leftOne subject">
                <ul style="display:inline-flex">
                  <li style="width:200px" class="ccyc"><el-icon><Back /></el-icon> {{item.name}}</li>
                  <el-tooltip effect="dark" :content="item.subjectName" placement="top" :disabled="!item.subjectName || item.subjectName.length < 12">
                    <li style="width:180px" class="ccyc">
                      <span >科目:{{item.subjectName}}</span>
                    </li>
                  </el-tooltip>
                  <li>类型: {{$inventoryTypeFilter(item.inventoryType)}}</li>
                  <li>单位: {{item.unit}}</li>
                </ul>
                <!-- <span>存货名: {{item.name}}</span>
                <span>科目名称: {{ item.subjectName }}</span>
                <span>类型: {{$inventoryTypeFilter(item.inventoryType)}}</span>
                <span>单位: {{item.unit}}</span> -->
              </div>
            </el-col>
            <el-col :span="24" :class="item.useCount || item.usePrice || item.useAmount?'tianshu':'tianshu2'">
              <el-row >
                <el-col :span="4">
                  <div class="left-box">
                    {{item.saleCount}}
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="left-box">
                    {{item.saleAmount}}
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="left-box">
                    {{item.saleCount}}
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="left-box">
                    <span v-if="item.saleCount">{{(item.saleAmount/item.saleCount).toFixed(2)}}</span>
                    <span v-else>0</span>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="left-box">
                    {{item.saleAmount}}
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
        </el-scrollbar>
        <!-- 合计 -->
        <el-row  style="background-color: #f5f7fa;">
          <el-col :span="4">
            <div class="left-box">
              {{ rightSumAmount }}
            </div>
          </el-col>
          <el-col :span="6">
            <div class="left-box">
              {{ rightSumAmount2 }}
            </div>
          </el-col>
          <el-col :span="4">
            <div class="left-box">
              {{ rightSumAmount }}
            </div>
          </el-col>
          <el-col :span="4">
            <div class="left-box">
              --
            </div>
          </el-col>
          <el-col :span="6">
            <div class="left-box">
              {{ rightSumAmount2 }}
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="pagination">
        <qzf-pagination v-show="rightPageTotal>0" :total="rightPageTotal" :pageSizes="[10, 20, 50, 100, 200 ,300,500,1000,3000]" v-model:page="this.rightListQuery.page" v-model:limit="this.rightListQuery.limit" @pagination="changeRightInventory" />
      </div>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      v-model="daoruFormVisible"
      title="导入"
      height="50vh"
    >
      <el-form size="small" label-position="right" label-width="5px">
        <el-form-item label="">
          <el-input
            style="width: 60%; margin-right: 10px"
            v-model="path"
          ></el-input>
          <input
            size="small"
            ref="excel-upload-input"
            class="excel-upload-input"
            style="display: none"
            type="file"
            accept=".xlsx, .xls, .xml"
            @change="handleClick"
          />
          <el-button type="primary" @click="handleUpload()" size="small"
            >请选择文件</el-button
          >
        </el-form-item>
      </el-form>
      <div>
        <div style="text-align: right">
          <el-button
            size="small"
            type="primary"
            @click="uploadExcel"
            v-loading="daoruLoading"
            >上传</el-button
          >
        </div>
      </div>
    </el-dialog>

    <!-- 暂估入库 -->
    <inventoryStock @success="zgrkSuccess" ref="zgrk" name="暂估入库" from="convert" @convert="doProduce(0)"></inventoryStock>
  </div>
</template>

<script>
import { tradeCheckInfo, tradeCheck, reverseTrade } from"@/api/inventory.js"
import inventoryStock from "./components/inventoryStock.vue"
import costSetting from "./components/costSetting.vue"
import { tradeCheckSheet } from '@/api/export'
import { INVENTORY_TYPE_OPTION } from '@/utils/commonData'
import { tradeChecks } from '@/api/import'

export default {
  name:'tradeConvert',
  components: {
    inventoryStock,
    costSetting,
  },
  computed: {
    // 是否全选
    isAllSelected() {
      if(this.info.leftInventory.length == 0){
        return false
      }
      return this.info.leftInventory.every(el => {
        return el.checked;
      });
    },
    //left金额合计 数量 金额 出库数量 出库金额 
    sumAmount() {
      let count = 0;
        this.info.leftInventory.map(v => {
          if (v.count == undefined){
            v.count = 0
          }
          count += v.count*1
        })
      return Number(count.toFixed(6));
    },
    sumAmount2() {
      let count2 = 0;
        this.info.leftInventory.map(v => {
          if (v.amount == undefined){
            v.amount = 0
          }
          count2 += v.amount*1
        })
      return Number(count2.toFixed(6));
    },
    sumAmount3() {
      let count3 = 0;
        this.info.leftInventory.map(v => {
          if (v.useCount == undefined){
            v.useCount = 0
          }
          count3 += v.useCount*1
        })
      return Number(count3.toFixed(6));
    },
    sumAmount4() {
      let count4 = 0;
        this.info.leftInventory.map(v => {
          if (v.useAmount == undefined){
            v.useAmount = 0
          }
          count4 += v.useAmount*1
        })
      return Number(count4.toFixed(6));
    },
    //right销售数量 销售金额 存货数量 存货金额 生产数量  生产金额
    rightSumAmount() {
      let count = 0;
        this.info.rightInventorys.map(v => {
          if (v.saleCount == undefined){
            v.saleCount = 0
          }
          count += v.saleCount*1
        })
      return Number(count.toFixed(6));
    },
    rightSumAmount2() {
      let count2 = 0;
        this.info.rightInventorys.map(v => {
          if (v.saleAmount == undefined){
            v.saleAmount = 0
          }
          count2 += v.saleAmount*1
        })
      return Number(count2.toFixed(6));
    },
  },
  data() {
    return {
      daoruFormVisible:false,
      daoruLoading:false,
      rawFile: "",
      path: "",
      selects: [],
      radio: 0,//领料加工是否显示留底金额
      loading: false,
      formLabelWidth:'150px',
      listQuery: {
        refresh: 0,//1 强制刷新
        hideEndAmount0: false,//true 隐藏金额为0的
        showWpp: false,
        showWppTemp:true,
        type:[2],
        match:false
      },
      info: {
        leftInventory:[],
        rightInventorys:[]
      },
      oldInfo:{},
      total: {
        ckzje: 0,//出库总金额
        cbbl: 0,//成本比例
        sczje:0,//生产总金额
      },
      leftListQuery: {
        limit: 20,
        page:1,
        inventoryName: "",
      },
      leftPageTotal:10,
      rightListQuery: {
        limit: 20,
        page:1,
        inventoryName: "",
      },
      rightPageTotal:10,
      daochuLoading:false,
      beforeFreshInfo:{},
      options:INVENTORY_TYPE_OPTION,
      showWppOptions:[
        { value: true, label: '显示' },
        { value: false, label: '隐藏' }
      ]
    }
  },
  created() {
    this.init()
    this.contentStyleObj= this.$getHeight(370)
  },
  methods:{
    changeShow(val){
      this.listQuery.showWpp = !val
      this.init()
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      let path = e.target.value; //路径
      this.path = path; //用路径作为标题
      this.rawFile = rawFile;
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    uploadExcel() {
      this.daoruLoading = true;
      const param = new FormData(); // 创建form对象
      param.append("file", this.rawFile, this.rawFile.name);
      tradeChecks(param).then((res) => {
        console.log(res)
        if (res.data.msg == "success") {
          let infoTemp = res.data.data.data
          if(infoTemp.leftInventory){
            infoTemp.leftInventory.forEach(item => {
              this.info.leftInventory.forEach(val => {
                if(item.id == val.id){
                  val.useCount = item.useCount
                  if(val.count == item.useCount){//当存货数量=出库数量 出库金额=存货金额
                    val.useAmount = val.amount
                  }else{
                    val.useAmount = item.useAmount
                  }
                  if(!item.useCount){//如果出库数量为零 出库金额一样为零
                    val.useAmount = 0
                  }
                }
                // if(item.inventoryType == val.inventoryType && item.name == val.name && item.unit == val.unit){
                //   val.useCount = item.useCount
                //   if(val.count == item.useCount){//当存货数量=出库数量 出库金额=存货金额
                //     val.useAmount = val.amount
                //   }else{
                //     val.useAmount = item.useAmount
                //   }
                //   if(!item.useCount){//如果出库数量为零 出库金额一样为零
                //     val.useAmount = 0
                //   }
                // }
              })
            });
          }
          // this.info = res.data.data.data
          if(this.info.leftInventory){
            this.info.leftInventory.map(v=>{
              if(v.count){
                v.usePrice = (v.amount/v.count).toFixed(2) * 1
              } else {
                v.usePrice = 0
              }
            })
          }
          if(this.info.rightInventorys){
            this.info.rightInventorys.map(v=>{
              if(v.saleCount){
                v.usePrice = (v.saleAmount/v.saleCount).toFixed(2) * 1
              }else{
                v.usePrice = (v.saleAmount).toFixed(2) * 1
              }
            })
          }
          this.oldInfo = this.$qzfCopy(this.info)
          this.changeLeftInventory()
          this.changeRightInventory()
          this.initPrice()
          this.daoruLoading = false;
          this.daoruFormVisible = false;
        }
      });
    },
    //分页筛选
    changeLeftInventory(a){
      let left = this.oldInfo.leftInventory
      let coms = []
      if(a == 'find'){
        this.leftListQuery.page = 1
        // this.leftListQuery.limit = 20
        coms = left.filter(v=>{
          return v.name.indexOf(this.leftListQuery.inventoryName) > -1
        })
      } else {
        if(this.leftListQuery.inventoryName){
          coms = left.filter(v=>{
            return v.name.indexOf(this.leftListQuery.inventoryName) > -1
          })
        }else{
          coms = left
        }
      }

      ////console.log(coms);
      this.leftPageTotal = coms ? coms.length : 0 //判断一下
      this.info.leftInventory = coms ? coms.slice((this.leftListQuery.page - 1) * this.leftListQuery.limit, this.leftListQuery.page * this.leftListQuery.limit) : []
    },
    changeRightInventory(a){
      let right = this.oldInfo.rightInventorys
      let coms = []
      if(a == 'find'){
        this.rightListQuery.page = 1
        this.rightListQuery.limit = 20
        coms = right.filter(v=>{
          return v.name.indexOf(this.rightListQuery.inventoryName) > -1
        })
      } else {
        if(this.rightListQuery.inventoryName){
          coms = right.filter(v=>{
            return v.name.indexOf(this.rightListQuery.inventoryName) > -1
          })
        }else{
          coms = right
        }
      }
      this.rightPageTotal = coms ? coms.length : 0
      this.info.rightInventorys = coms ? coms.slice((this.rightListQuery.page - 1) * this.rightListQuery.limit, this.rightListQuery.page * this.rightListQuery.limit) : []
    },

    changeLeft(item, type){
      if(item.useCount == item.count && item.usePrice == (item.amount/item.count).toFixed(2)){
        item.useAmount = item.amount
        this.leftTotal()
        return
      }
      if(type == 'count'){
       if(item.usePrice){
          item.useAmount = (item.useCount * item.usePrice).toFixed(2) * 1
        }else{
          if(item.count){
            item.usePrice = (item.amount/item.count).toFixed(2) * 1
            item.useAmount = (item.useCount * item.usePrice).toFixed(2) * 1
          }
        }
      }else if(type == 'price'){
        item.useAmount = (item.useCount * item.usePrice).toFixed(2) * 1
        //console.log(item.count);
        // item.usePrice = (item.amount/item.count).toFixed(2) * 1
      }else{
        if(item.useCount){
          item.usePrice = (item.useAmount/item.useCount).toFixed(2) * 1
        }
      }
      //计算科目合计 不需要了
      this.leftTotal()
    },
    changeRight(item, type){
      //计算科目合计  不需要了
      this.leftTotal()
    },
    leftTotal(){
      //存货
      let inventoryAmount = 0
      let actualArr = this.selects.length > 0 ? this.selects : this.oldInfo.leftInventory
      if(actualArr){
        actualArr.map(v=>{
          if(v.useAmount){
            inventoryAmount+=v.useAmount
          }
        })
      }
      inventoryAmount = inventoryAmount.toFixed(2)*1

      //生产总金额
      let inventoryAmountSc = 0
      if(this.oldInfo.rightInventorys){
        this.oldInfo.rightInventorys.map(v=>{
          if(v.saleAmount){
            inventoryAmountSc+=v.saleAmount
          }
        })
      }
      this.total.sczje = inventoryAmountSc.toFixed(2) * 1
      this.total.ckzje = (inventoryAmount).toFixed(2) * 1
      this.total.cbbl = this.total.sczje?((this.total.ckzje/this.total.sczje) * 100).toFixed(2):0
    },
    cancel(){
      this.listQuery={
        refresh: 0,//1 强制刷新
        hideEndAmount0: false,//true 隐藏金额为0的
        showWpp: false,
        showWppTemp: true,
        type:[2],
        match:false
      },
      this.init()
    },
    sortChange(type,flag){
      this.listQuery.countDesc = type
      this.listQuery.order = flag
      this.init()
    },
    //初始化数据
    init(refresh){
      if(refresh){
        this.listQuery.refresh = 1
        this.beforeFreshInfo = this.$qzfCopy(this.info)
      }else{
        this.listQuery.refresh = 0
        this.beforeFreshInfo = {}
      }
      this.loading = true
      tradeCheckInfo(this.listQuery).then(res=>{
        this.loading = false
        this.info = res.data.data.info
        if(this.info.leftInventory){
          this.info.leftInventory.map(v=>{
            if(v.count){
              v.usePrice = (v.amount/v.count).toFixed(2) * 1
            } else {
              v.usePrice = 0
            }
          })
        }
        if(this.info.rightInventorys){
          this.info.rightInventorys.map(v=>{
            if(v.useCount){
              v.usePrice = (v.useAmount/v.useCount).toFixed(2) * 1
            }else{
              v.usePrice = (v.useAmount).toFixed(2) * 1
            }
          })
        }
        this.oldInfo = this.$qzfCopy(this.info)
        if(refresh){
          this.updateLeft(this.beforeFreshInfo)
        }
        this.changeLeftInventory()
        this.changeRightInventory()
        this.initPrice()
      })
    },
    initPrice(){
      //计算缓存的出库单价
      if(this.oldInfo.leftInventory){
        this.oldInfo.leftInventory.map(v=>{
          this.changeLeft(v, 'amount', this.oldInfo.leftInventory)
        })
      }
      if(this.oldInfo.rightInventorys){
        this.oldInfo.rightInventorys.map(v=>{
          this.changeRight(v, 'amount', this.oldInfo.rightInventorys)
        })
      }
      this.leftTotal()
    },
    //执行领料加工
    doProduce(type){
      let produceParam = this.$qzfCopy(this.oldInfo)
      if(type == 0){
        produceParam.leftInventory = this.selects.length != 0 ? this.selects : this.oldInfo.leftInventory
      }
      let param = {
        info: produceParam,
        type,
      }
      this.loading = true
      tradeCheck(param).then(res=>{
        this.loading = false
        if(res.data.msg == "success"){
          this.$qzfMessage("操作成功")
          this.$bus.emit('inventoryCheckUpdate')
          this.init()
        }
      })
    },
    //领料加工 确认
    doProduceClick(){
      // let count = 0
      // this.oldInfo.leftInventory.map(v=>{
      //   count += v.useAmount*1
      // })
      // if(!count){
      //   this.$qzfMessage("出库金额不可为0", 1)
      //   return
      // }
      //判断是否需要暂估入库
      let zgrkList = []
      let produceList = this.selects.length != 0 ? this.selects : this.oldInfo.leftInventory
      if(produceList){
        produceList.map(v=>{
          if((v.useCount - v.count) > 0){
            let count = parseFloat((v.useCount - v.count).toFixed(6) * 1)
            // let amount = (v.useAmount - v.amount).toFixed(2) * 1
            let danjia = v.useCount == 0?0:(v.useAmount/v.useCount).toFixed(6) * 1
            if(v.useCount == 0){
              danjia = (v.amount/v.count).toFixed(2) * 1
            }
            if(danjia == 0){
              danjia = v.count == 0?0:(v.amount/v.count).toFixed(6) * 1
            }
            let amount = (count*danjia).toFixed(2) * 1
            zgrkList.push({
              count: count, // 数量
              amount:  amount,// 金额
              danjia: danjia,
              inventoryId:v.id,
            })
          } else if ((v.useCount - v.count) == 0 && (v.useAmount - v.amount) > 0){
            let amount = (v.useAmount - v.amount).toFixed(2) * 1
            zgrkList.push({
              count: 1, // 数量
              amount:  amount,// 金额
              danjia: amount,
              inventoryId:v.id,
            })
          }
        })
      }
      if(zgrkList.length != 0){
        let that = this
        this.$prompt("请输入暂估单价比例（仅在单价为0时生效）", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "手动设置单价",
          inputValue:80,
          title:'请输入暂估单价比例（%)',
          customClass:'classPrompt'
        }).then(({value}) => {
          let floatValue = Number(value)
          let zgrkList2 = []
          zgrkList.map(v=>{
            let s = true
            that.oldInfo.rightInventorys.map(z=>{
              if(v.inventoryId == z.id){
                s = false                
                if(v.amount == 0){
                  let danjia = (z.saleAmount/z.saleCount*floatValue/100).toFixed(2) * 1
                  zgrkList2.push({
                    count: v.count, // 数量
                    amount:  (danjia*v.count).toFixed(2) * 1,// 金额
                    danjia: danjia,
                    inventoryId:v.inventoryId,
                  })
                } else {
                  zgrkList2.push(v)
                }
              }
            })
            if(s){
              zgrkList2.push(v)
            }
          })
          that.$refs.zgrk.init(zgrkList2)
          return
        }).catch(() => {
          that.$refs.zgrk.init(zgrkList)
        })
      }else{
        this.doProduce(0)
      }
    },
    //暂估入库回调
    zgrkSuccess(info){
      // 用oldInfo是控制全部的 不然就只是单页的数据
      info.items.map(zgrk=>{
        this.oldInfo.leftInventory.map(v=>{
          // if(subject.inventorys){
            // subject.inventorys.map(v=>{
              if(v.id == zgrk.inventoryId){
                if(v.count == 0){
                  v.useAmount = (v.amount + zgrk.amount).toFixed(2) * 1
                  if (v.useCount != 0 ){
                    v.usePrice = (v.useAmount/v.useCount).toFixed(2) * 1
                  }
                }
                v.count = parseFloat((v.count + zgrk.count).toFixed(6) * 1)
                v.amount = (v.amount + zgrk.amount).toFixed(2) * 1
              }
            // })
          // }
        })
      })
      // 重新获取一下
      // this.init()
    },
    //一键领料
    oneKeyLl(){
      this.oldInfo.leftInventory.map(v=>{
        v.useCount = v.count
        v.useAmount = v.amount
      })
      this.initPrice()
    },
    ablck(){
      let that = this
      this.$prompt('请输入成本比例（%）,当出库数量等于存货数量时，系统默认出库金额全部出库，成本比例可能存在误差', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        // 成功的操作 写在这里
        let amount = this.total.sczje * value / 100
        that.inProportion(amount)
      })
    },
    //按比例出库
    inProportion(amount){
      if(amount == 0){
        this.$qzfMessage("生产总金额不可为0", 1)
        return
      }
      let amountSum = 0
      let danweis = ["个","台","辆","张","支","瓶","包","箱","卷","桶","袋","打","双","对","套","块","枚","罐","盆","束","羽","条","头","尾","本","份","板","片","粒","管","根","把","只","件","架","箱","付","副"]
      let ckList = this.selects.length != 0 ? this.selects : this.oldInfo.leftInventory
      ckList.map(v=>{
        amountSum+=v.amount
      })
      if(amount > amountSum){
        this.$qzfMessage("成本大于当前存货总金额", 1)
        return
      }
      ckList.map(v=>{
        v.useAmount = amountSum?(v.amount/amountSum*amount).toFixed(2) * 1:0
        v.usePrice = v.count?(v.amount/v.count).toFixed(2) * 1:0
        v.useCount = v.usePrice?parseFloat((v.useAmount/v.usePrice).toFixed(6) * 1):0
        if(this.$checkStrInArray(danweis,v.unit)){
          v.useCount = Number.isInteger(v.count) ? Math.ceil(v.useCount.toFixed(2) * 1) : v.useCount.toFixed(0)*1
          v.usePrice = v.useCount?(v.useAmount/v.useCount).toFixed(2) * 1:0
        }
        v.useCount == v.count ? v.useAmount = v.amount:v.useAmount
      })
      this.initPrice()
    },
    //重置贸易核算
    reset(){
      this.loading = true
      reverseTrade({}).then(res=>{
        this.loading = false
        if(res.data.msg == "success"){
          this.info = res.data.data.info
          if(this.info.leftInventory){
          this.info.leftInventory.map(v=>{
            if(v.count){
              v.usePrice = (v.amount/v.count).toFixed(2) * 1
            } else {
              v.usePrice = 0
            }
          })
        }
        if(this.info.rightInventorys){
          this.info.rightInventorys.map(v=>{
            if(v.useCount){
              v.usePrice = (v.useAmount/v.useCount).toFixed(2) * 1
            }else{
              v.usePrice = (v.useAmount).toFixed(2) * 1
            }
          })
        }
        this.oldInfo = this.$qzfCopy(this.info)
        this.changeLeftInventory()
        this.changeRightInventory()
          this.initPrice()
          this.$qzfMessage("重置成功")
          this.$bus.emit('inventoryCheckUpdate')
        }
      })
    },
    updateLeft(item){
      for (let i = 0; i < this.oldInfo.leftInventory?.length; i++) {
        const element = this.oldInfo.leftInventory[i];
        item.leftInventory.map((v) => {
          if (v.id == element.id) {
            element.useAmount = v.useAmount;
            element.useCount = v.useCount;
            element.usePrice = v.usePrice;
          }
        });
      }
      // this.changeLeftInventory()
    },
    //导入更新右侧数据
    daoruRight1(){
      this.rawFile = ""
      this.path = ""
      this.daoruFormVisible = true;
    },
    //导出导入更新右侧数据
    daochuRight(){
      this.daochuLoading = true
      tradeCheckSheet(this.listQuery).then(res =>{
        this.daochuLoading = false
        window.open(res.data.data.url)
      })
    },
    //单选
    singleSelect(row, index) {
      row.checked = !row.checked;
      this.info.leftInventory.splice(index, 1, row);
      this.screenChecked()
    },
    // 全选、全不选
    allSelect() {
      let checked = true;
      // 全选
      if (this.isAllSelected) {
        checked = false;
      }
      this.info.leftInventory = this.info.leftInventory.map(el => {
        el.checked = checked;
        return el;
      });
      this.screenChecked()
    },
    screenChecked(){
      this.selects = this.info.leftInventory.filter(v=>{return v.checked})
      this.leftTotal()
    },
    ablszdj(){
      let that = this
      this.$prompt('请输入比例（%）', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        // 成功的操作 写在这里
        let amount = value / 100
        that.computedCkdj(amount)
      })
    },
    computedCkdj(amount){
      let djList = this.selects.length != 0 ? this.selects : this.oldInfo.leftInventory
      this.oldInfo.rightInventorys?.map(v=>{
        djList.map(e=>{
          if(e.id == v.id){
            let ckdj = 0
            ckdj = Number((v.saleAmount/v.saleCount*amount*1).toFixed(2))
            let ckAmount = ckdj * e.useCount*1
            if(ckAmount<e.amount){
              e.usePrice = ckdj
            }
          }
        })
      })
      //计算缓存的出库单价
      if(this.oldInfo.leftInventory){
        this.oldInfo.leftInventory.map(v=>{
          this.changeLeft(v, 'price', this.oldInfo.leftInventory)
        })
      }
      this.leftTotal()
    }
  }
}
</script>

<style lang="scss" scoped>
 
.content{
  font-size: 13px;
  margin: 20px;
  // text-indent: 4px;
  .left-content{
    width: 49%;
    margin-right: 1%;
    height: 500px;
    display: inline-block;
  }
  .right-content{
    width: 50%;
    // margin-right: 1%;
    height: 500px;
    display: inline-block;
  }
  .left-content-item{
    width: 100%;
    border: 1px solid #efefef;
    .left-total{
      width: 100%;
      background: #fff;
      height: 30px;
      line-height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      //padding: 0 10px;

      span {
        padding-right: 20px;
      }
      :deep(.el-checkbox__input.is-checked + .el-checkbox__label) {
        color: #0a0a0a !important;
      }
      :deep(.el-checkbox__inner) {
        margin-top: 2px !important;
      }
    }
    .left-title{
      width: 100%;
      background: var(--themeColor,#17a2b8);
      height: 30px;
      text-align: center;
      line-height: 30px;
      color: #fff;
      position: relative
    }
    .subject{
      background: #efefef;
    }
    .left-box{
      border-right: 1px solid #efefef;
      line-height: 30px;
      height: 30px;
      border-top: 1px solid #efefef;
      text-align: center;
      input {
        background-color: aliceblue;
      }
    }
    .leftOne {
      border-right: 1px solid #efefef;
      line-height: 30px;
      height: 30px;
      border-top: 1px solid #efefef;
      padding: 0 10px;
      span {
      padding-right: 25px;
    }
    }
    &:last-child {
      // border-right: none;
    }
  }
}
.right-button{
  position: fixed;
  top: 250px;
  right: 0;
  height: 120px;
  width: 40px;
  font-size: 14px;
  margin: 0 auto;  
  line-height: 24px;
  background: var(--themeColor,#17a2b8);
  color: #fff;
  border-color: var(--themeColor,#17a2b8);
  border: 0;
  cursor:pointer;
}
// input{
//   width: calc(100% - 10px);
//   height: 100%;
//   display: inline-block;
//   outline: none;
// }
.ccyc{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.left_sort_box{
  display: flex;
  align-items: center;
  justify-content: center;
}
.sort_style{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
  cursor: pointer;
  margin-left:5px;
  .sort-caret {
    width: 0;
    height: 0;
    border: solid 5px transparent;
  }
  .sort-caret.descending {
    border-top-color: var(--themeColor,#17a2b8);
  }
  .ascending {
    border-bottom-color: var(--themeColor,#17a2b8);
  }
}
</style>

<style lang="scss">
  .classPrompt{
    .el-message-box__container{
      color:red
    }
}
</style>
